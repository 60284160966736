<template>
  <v-container
    class="full-height full-width d-flex flex-column align-center justify-center"
  >
    <div class="text-h3">{{ $t('common.pageNotFound') }}</div>
    <img :width="'50%'" :src="notFound" />
  </v-container>
</template>

<script>
import notFound from '@/assets/404-not-found.svg';
export default {
  name: 'NotFoundPage',
  data() {
    return {
      notFound
    };
  }
};
</script>

<style lang="scss" scoped></style>
